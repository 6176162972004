<template>
  <div class="absence-requests">
    <v-snackbar
      v-model="snackbar"
      :color="transactionError ? 'error' : 'success'"
      top
    >
      {{ transactionStatus }}
      <v-btn color="white" flat @click="onCloseSnackbar"> Schließen</v-btn>
    </v-snackbar>
    <div v-if="userMetaData.userId" class="absence-requests-list">
      <div class="absence-filter-section">
        <user-filter
          v-if="userHasReadRights"
          class="user-filter"
          :user-request-accuracy="{ year: selectedYear }"
          :updateUserSelection="onChangeUsers"
          :contentIsLoaded="requestHandled"
        ></user-filter>

        <div class="status-and-year-filter">
          <v-autocomplete
            v-model="selectedReqStatuses"
            :search-input.sync="reqStatusSearchTerm"
            multiple
            ref="reqStatusFilter"
            label="Statusfilter"
            :items="AbsenceRequestStatuses"
            :filter="onSearchForReqStatus"
            @change="onChangeReqStatuses"
            :disabled="!requestHandled"
            class="status-filter"
            :no-data-text="PLACEHOLDER_NO_SELECTABLE_DATA"
            hide-selected
          >
            <template v-slot:selection="data">
              <v-chip
                :selected="data.selected"
                close
                class="chip--select-multi"
                @input="() => onClearSelectedReqStatus(data.item)"
              >
                {{ getFormattedRequestStatusOption(data.item) }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              {{ getFormattedRequestStatusOption(data.item) }}
            </template>
          </v-autocomplete>

          <v-text-field
            type="number"
            class="year-filter"
            v-model="selectedYear"
            label="Jahr"
            @change="updateDataAfterChange"
          >
          </v-text-field>
        </div>
      </div>

      <v-expansion-panel
        v-if="absenceRequests.length"
        expand
        :value="getArrayOfPreopenedRequests()"
      >
        <v-expansion-panel-content
          v-for="req of absenceRequests"
          :key="req.id"
          :data-employee-id="req.employeeId"
          :data-absence-request-id="req.id"
          :id="req.id"
          :ref="req.id"
        >
          <template v-slot:header>
            <div
              class="expandable-header"
              :class="header.className"
              v-for="(header, i) of getExpandableHeaderData(req)"
              :key="i"
            >
              <div class="expandable-header-column">
                <div class="header-title">{{ header.title }}</div>
                <div class="header-value">{{ header.value }}</div>
              </div>
            </div>
          </template>

          <div class="expandable-body">
            <div class="request-status" v-if="isOnMobileBrowser()">
              <div class="bold">Status</div>
              <div>{{ getStatus(req) }}</div>
            </div>
            <div class="expandable-body-column absence-request-note">
              <span>{{ !req.comment ? "Kein Kommentar" : "Kommentar:" }}</span>
              {{ req.comment }}
            </div>
          </div>
          <div
            class="cancel-processed-button"
            v-if="
              req.state === 'PROCESSED' ||
                req.state === 'CANCELLATION_REQUESTED'
            "
          >
            <v-btn
              depressed
              color="error"
              v-if="userIsAdmin"
              v-on:click="() => processAbsenceRequest(req, true)"
            >
              Antrag Stornieren
            </v-btn>
            <v-btn
              depressed
              color="error"
              :disabled="req.state === 'CANCELLATION_REQUESTED'"
              v-else
              v-on:click="() => cancelAbsenceRequest(req, true)"
            >
              Stornierung beantragen
            </v-btn>
          </div>
          <div
            class="expandable-body"
            v-for="(body, recipientIndex) of getExpandableBodyData(req)"
            :data-recipient-id="req.recipients[recipientIndex].recipientId"
            :key="recipientIndex"
          >
            <div
              class="expandable-body-column"
              v-for="(entry, k) of body"
              :class="entry.className"
              :key="k"
            >
              <div v-if="entry.className === 'send-reminder-btn'">
                <v-tooltip top :disabled="entry.value === ''">
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <v-btn
                        elevation="2"
                        :disabled="entry.value !== ''"
                        v-on:click="
                          () =>
                            sendAbsenceRequestReminder(
                              req.recipients[recipientIndex]
                            )
                        "
                      >
                        {{
                          isOnMobileBrowser()
                            ? "Erinnern"
                            : "Erinnerungsmail schicken"
                        }}
                      </v-btn>
                    </div>
                  </template>
                  <span>{{ entry.value }}</span>
                </v-tooltip>
              </div>
              <div v-else>{{ entry.value }}</div>
            </div>
          </div>
          <div class="expandable-body buttons">
            <div class="create-request-btn" v-if="shouldShowCreateBtn(req)">
              <v-btn
                depressed
                color="info"
                v-on:click="() => processAbsenceRequest(req)"
              >
                Eintrag Erstellen
              </v-btn>
            </div>
            <div class="cancel-request-btn" v-if="canBeCancelled(req)">
              <v-btn
                depressed
                color="error"
                v-on:click="() => cancelAbsenceRequest(req)"
              >
                Antrag Stornieren
              </v-btn>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <div v-else>
        <div id="loadingSpinner" v-if="!requestHandled"></div>
        <div v-else-if="userMetaData.userId">
          <div class="no-absence-date" v-if="!errorLoadingData">
            <strong>
              Es gibt keine Einträge für die ausgewählten Filterkriterien
            </strong>
          </div>
          <div v-else class="loading-data-error">
            <strong>
              Beim Versuch die Daten zu laden ist ein Fehler aufgetreten:
              {{ errorLoadingData }}
            </strong>
          </div>
        </div>
      </div>
    </div>
    <v-dialog lazy persistent v-model="isCreateMode">
      <v-card v-if="Object.keys(selectedAbsenceRequestData).length">
        <v-card-title primary-title>
          <v-layout justify-center row>
            <v-flex>
              <h2 v-if="cancelOverview">
                Urlaub von {{ requestCreatorFullName }} stornieren
              </h2>
              <h2 v-else>
                Neuen Eintrag für {{ requestCreatorFullName }} erstellen
              </h2>
            </v-flex>
          </v-layout>
        </v-card-title>

        <v-layout class="select-vacation-date-section">
          <v-flex cols="12" sm="6" md="4">
            <v-text-field
              :value="formatSelectedDate(selectedAbsenceRequestData.startDate)"
              label="Ab wann?"
              prepend-icon="event"
              disabled
              class="absence-start-date"
            ></v-text-field>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex cols="12" sm="6" md="4">
            <v-text-field
              :value="formatSelectedDate(selectedAbsenceRequestData.endDate)"
              label="Bis wann?"
              prepend-icon="event"
              disabled
              class="absence-end-date"
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-textarea
          label="Kommentar"
          class="comment-area"
          disabled
          :value="selectedAbsenceRequestData.comment"
          auto-grow
          outlined
          rows="1"
          row-height="15"
          placeholder="Kein Kommentar"
        ></v-textarea>

        <v-card-title class="table-caption">
          <v-layout row>
            <v-flex>
              <div>{{ formattedCurrentMonthYear }}</div>
            </v-flex>
          </v-layout>
        </v-card-title>

        <v-data-table
          :headers="dataTableHeaders"
          :rows-per-page-items="[1]"
          :items="[dataTableItems[selectedTablePage]]"
          hide-actions
          class="absence-request-create-table"
        >
          <template v-slot:headerCell="props">
            <div class="table-header">
              <div>{{ props.header.text.split(" ")[0] }}</div>
              <div>{{ props.header.text.split(" ")[1] }}</div>
            </div>
          </template>
          <template v-slot:items="props">
            <td
              v-for="item in props.item"
              :key="item.date"
              :class="item.class"
              :data-entry-date="item.date"
            >
              <v-tooltip
                v-if="item.class !== 'weekend'"
                bottom
                :disabled="!item?.selectedValue?.name && !item?.initialValue?.name"
              >
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-select
                      :class="{
                        added: item.isNew,
                        changed: item.isChanged,
                        'heavier-font': item.isNew || item.isChanged
                      }"
                      :items="vacationTypeOptions"
                      v-model="item.selectedValue"
                      @change="() => onChangeAbsence(item)"
                      hide-details
                    >
                      <template v-slot:selection="{ item }">
                        {{ item && $vacation.byAbbr(item.type).abbr }}
                      </template>
                      <template v-slot:item="{ item }">
                        {{ item?.name }}
                      </template>
                    </v-select>
                  </div>
                </template>
                <span class="tooltip-text">
                  <span v-if="item?.selectedValue">{{ item.selectedValue?.name }}</span>
                  <span v-if="item?.isChanged">Vorher: {{ item.initialValue?.name }}</span>
                </span>
              </v-tooltip>
            </td>
          </template>
        </v-data-table>

        <v-card-title class="table-footer">
          <v-layout row>
            <v-flex>
              <div v-if="cancelOverview">
                Insgesamt stornierte Urlaubstage:
                {{ -1 * amountOfRequestedVacations }}
              </div>
              <div v-else>
                Insgesamt Urlaubstage angefordert:
                {{ amountOfRequestedVacations }}
              </div>
              <div v-for="entitlementInfo in yearlyEntitlementInfo">
                <span v-if="typeof entitlementInfo === 'string'">{{
                  entitlementInfo
                }}</span>
                <v-btn
                  v-else
                  color="secondary"
                  class="ma-2"
                  @click="() => openEntitlementForm(Number(entitlementInfo))"
                  :disabled="loadingForm"
                >Jahresanspruch {{ entitlementInfo }} erstellen
                  <v-icon right>edit</v-icon>
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
        </v-card-title>

        <div class="table-controls" v-if="shouldShowDataTableControls">
          <v-btn
            fab
            small
            @click="goToPreviousMonth"
            :disabled="isNavButtonDisabled(selectedAbsenceRequestData.startDate)"
          >
            <v-icon class="item-icon">chevron_left</v-icon>
          </v-btn>
          <v-btn
            fab
            small
            @click="goToNextMonth"
            :disabled="isNavButtonDisabled(selectedAbsenceRequestData.endDate)"
          >
            <v-icon class="item-icon">chevron_right</v-icon>
          </v-btn>
        </div>
        <v-card-actions>
          <v-layout justify-end row class="action-buttons">
            <v-flex shrink class="save-btn">
              <v-btn
                color="success"
                @click="
                  submitProcessedAbsenceRequest(cancelOverview);
                  cancelOverview = false;
                "
                :disabled="!!yearlyEntitlementInfo.find(val => typeof val === 'number')"
              >Speichern</v-btn>
            </v-flex>
            <v-flex shrink class="cancel-btn">
              <v-btn color="error" @click="cancelNewAbsenceRequest">
                Abbrechen
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <entitlement-form
      v-if="shouldShowEntitlementForm"
      :show-form="shouldShowEntitlementForm"
      :submit="saveEntitlement"
      :cancel="closeEntitlementForm"
      :title="
        `Jahres-Anspruch für ${requestCreatorFullName} in ${selectedEntitlementData.year} erstellen`
      "
      :initial-data="selectedEntitlementData"
    ></entitlement-form>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import EntitlementForm from "../entitlement/entitlementForm.vue";
import UserFilter from "../common/userFilter.vue";
import {
  AbsenceRequestStatuses,
  DATE_WITH_WEEKDAY_DATEFORMAT,
  FULL_DAY_LEAVE,
  HALF_DAY_HOLIDAY,
  HALF_DAY_HOLIDAY_HALF_LEAVE,
  HALF_DAY_LEAVE,
  HOLIDAY_LEAVE,
  MONTH_AS_WORD_WITH_YEAR_DATEFORMAT,
  PLACEHOLDER_NO_SELECTABLE_DATA,
  STATUS_CANCELLATION_REQUESTED,
  STATUS_CANCELLED,
  STATUS_NOT_PROCESSED,
  STATUS_PROCESSED,
  STATUS_REQUESTED,
  statusNamesByKey,
  userRoleKeysByValue,
  VACATION_TYPES,
  VacationMapping,
  VacationAbbreviation,
  YEAR_MONTH_DAY_DATEFORMAT
} from "@/constants";
import {
  AbsenceRequest,
  AbsenceRequestState,
  Absences,
  AbsencesInMonthlyOverview,
  EntitlementData,
  Recipient,
  RecipientState,
  User,
  UserEntitlementData,
  UserMetaData,
  UserRequestAccuracy
} from "@/models";
import {ABSENCE_DATA_ACTIONS, ABSENCE_DATA_GETTERS, AbsenceSpace} from "@/store/modules/absenceData";
import {
  formatDateToFormatStringInUTCTz,
  getDateContext,
  getUserMessageForErrorReason,
  hasReadRights,
  isBetween,
  roundToTwoDecimals
} from "@/utils";
import {
  addDays,
  addMonths,
  differenceInCalendarMonths,
  differenceInHours,
  endOfMonth,
  isAfter,
  isBefore,
  isWeekend,
  startOfDay,
  startOfMonth,
  subDays,
  subMonths
} from "date-fns";

type ExpandableHeader = Array<{ title: string; value: string; className: string }>;
type ExpandableBody = Array<{ className: string; value: string | boolean }>;

interface DataTableItemEntry {
  disabled: boolean;
  date: string;
  month: string;
  class: string;
  isNew: boolean;
  isChanged: boolean;
  selectedValue: VacationMapping | null;
  initialValue: VacationMapping | null;
}

type DataTableItem = DataTableItemEntry[];

@Component({
  components: { userFilter: UserFilter, entitlementForm: EntitlementForm }
})
export default class AbsenceRequestsOverview extends Vue {
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getUserMetaData)
  userMetaData!: UserMetaData;
  @AbsenceSpace.Action(ABSENCE_DATA_ACTIONS.fetchAllValidUsers)
  fetchAllValidUsers!: (
    userRequestAccuracy: UserRequestAccuracy
  ) => Promise<User[]>;
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getAllValidUsers)
  users!: User[];
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getAllAbsencesInMonth)
  fetchedAbsencesData!: Absences<AbsencesInMonthlyOverview>;
  @AbsenceSpace.Action(ABSENCE_DATA_ACTIONS.fetchAbsencesOfMonth)
  fetchAbsencesOfMonth!: ([year, month, users]: [
    number,
    number,
    string[]
  ]) => Promise<Absences<AbsencesInMonthlyOverview>>;
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getSelectedUsers)
  getSelectedUsers!: User[];

  absenceRequests: AbsenceRequest[] = [];

  requestHandled: boolean = false;
  errorLoadingData = null;

  filterUsers: string[] = [];
  userSearchTerm = "";

  selectedReqStatuses: string[] =
    this.getStatesFromQuery() == null
      ? [STATUS_NOT_PROCESSED, STATUS_REQUESTED]
      : this.getStatesFromQuery()!;
  reqStatusSearchTerm = "";

  selectedYear: number = Number(this.getYearFromQuery()) || new Date().getFullYear();

  isCreateMode = false;
  selectedAbsenceRequestData: AbsenceRequest = {} as AbsenceRequest;
  selectedDateContext: Date = new Date();

  transactionStatus = "";
  snackbar = false;
  transactionError = false;

  shouldShowEntitlementForm = false;
  selectedEntitlementData = {} as UserEntitlementData;
  loadingForm = false;

  cancelOverview = false;

  vacationTypeOptions = [
    null,
    ...VACATION_TYPES
  ];

  dataTableItems: DataTableItem[] = [];
  selectedTablePage: number = 0;

  userAbsences: { [key: string]: AbsencesInMonthlyOverview } = {};

  PLACEHOLDER_NO_SELECTABLE_DATA = PLACEHOLDER_NO_SELECTABLE_DATA;
  AbsenceRequestStatuses = AbsenceRequestStatuses;

  mounted() {
    this.handleRequests();
  }

  handleRequests() {
    const userRequestAccuracy: UserRequestAccuracy = {
      year: this.selectedYear,
      month: null
    };

    Promise.all([
      this.fetchAllValidUsers(userRequestAccuracy),
      this.fetchAbsenceRequests()
    ]).finally(() => {
      this.requestHandled = true;

      const hash = this.getHash();

      if (hash.length === 0) {
        return;
      }

      const requestElement = document.getElementById(hash);

      // Wait for the expansion panel to expand fully
      if (requestElement) {
        setTimeout(function() {
          requestElement.scrollIntoView();
        }, 200);
      }
    });
  }

  onCloseSnackbar() {
    this.snackbar = false;
    this.transactionStatus = "";
  }

  onSearchForReqStatus(reqStatus: AbsenceRequestState, queryText: string) {
    return this.getFormattedRequestStatusOption(reqStatus)
      .toLocaleLowerCase()
      .includes(queryText.toLowerCase());
  }

  onChangeUsers() {
    this.updateDataAfterChange();
  }

  onChangeReqStatuses() {
    this.reqStatusSearchTerm = "";
    this.updateDataAfterChange();
  }

  onClearSelectedReqStatus(reqStatus: AbsenceRequestState) {
    this.selectedReqStatuses = this.selectedReqStatuses.filter(
      status => status !== reqStatus
    );
    this.updateDataAfterChange();
  }

  async fetchUserMonthAbsence(employeeId: string, dateContext: Date) {
    const userRoleKey =
      userRoleKeysByValue[
        (this.users.find(({ userId }) => employeeId === userId) as User)
          .userRole
        ];
    return this.fetchAbsencesOfMonth([
      dateContext.getFullYear(),
      dateContext.getMonth() + 1,
      [employeeId]
    ])
      .then(data => {
        const key = this.dateToKey(dateContext);

        if (data && data[userRoleKey] && data[userRoleKey].length) {
          this.userAbsences[key] = data[userRoleKey][0];
        } else {
          this.userAbsences[key] = { absences:([] as string[]) } as AbsencesInMonthlyOverview;
        }
      })
      .catch(err => (this.errorLoadingData = err))
      .finally(() => (this.requestHandled = true));
  }

  private dateToKey(dateContext: Date | number) {
    return formatDateToFormatStringInUTCTz(
      dateContext,
      MONTH_AS_WORD_WITH_YEAR_DATEFORMAT
    );
  }

  processAbsenceRequest(
    absenceReq: AbsenceRequest,
    cancellation: boolean = false
  ) {
    const { startDate, endDate, employeeId, state } = absenceReq;

    if (
      state === STATUS_REQUESTED &&
      !confirm(
        "Dieser Antrag wurde noch nicht von allen angegebenen Ansprechpartnern genehmigt. Trotzdem eintragen?"
      )
    ) {
      return;
    }

    this.selectedDateContext = getDateContext(startDate);
    this.userAbsences = {};
    let startDateContext = getDateContext(startDate);

    // TODO very bad construct. Refactor me!
    Promise.all(
      Array.from({
        length:
          differenceInCalendarMonths(
            endOfMonth(getDateContext(endDate)),
            startOfMonth(startDateContext)
          ) + 1
      }).map((_, index) => {
        const res = this.fetchUserMonthAbsence(employeeId, startDateContext);
        startDateContext = addMonths(startDateContext, 1);
        return res;
      })
    ).then(() => {
      this.cancelOverview = cancellation;
      this.isCreateMode = true;
      this.selectedAbsenceRequestData = { ...absenceReq };
      this.dataTableItems = this.getDataTableItemsByMonth(true, cancellation);
      this.selectedTablePage = 0;
    });
  }

  submitProcessedAbsenceRequest(shouldCancelAfter: boolean) {
    const dateFormat = YEAR_MONTH_DAY_DATEFORMAT;

    const {
      selectedAbsenceRequestData: {
        employeeId,
        comment,
        startDate,
        endDate,
        id
      }
    } = this;

    const absencesToUpdate = {
      affectedEmployee: employeeId,
      data: this.dataTableItems
        .reduce((acc, dataTableItem) => {
          return [
            ...acc,
            ...dataTableItem.filter(
              ({ selectedValue }) => !!selectedValue?.name
            )
          ];
        }, [])
        .map(({ date, selectedValue }) => ({ type: selectedValue?.abbr, date })),
      startOfRange: formatDateToFormatStringInUTCTz(
        startOfMonth(getDateContext(startDate)),
        dateFormat
      ),
      endOfRange: formatDateToFormatStringInUTCTz(
        endOfMonth(new Date(Number(endDate) * 1000)),
        dateFormat
      ),
      remark: comment,
      requestId: id
    };

    this.axios
      .post("/api/updateAbsenceStatus", [absencesToUpdate])
      .then(response => {
        if (response) {
          this.showUserNotification(
            "Alle Abwesenheiten wurden erfolgreich eingetragen/beantragt",
            false
          );
        }
        this.cancelNewAbsenceRequest();

        this.fetchAbsenceRequests().finally(() => (this.requestHandled = true));

        if (shouldCancelAfter) {
          this.cancelAbsenceRequest(this.selectedAbsenceRequestData);
        }
      })
      .catch(reason => {
        this.showUserNotification(getUserMessageForErrorReason(reason), true);
      });
  }

  cancelNewAbsenceRequest() {
    this.isCreateMode = false;

    let timeOut: number;

    new Promise(() => {
      timeOut = setTimeout(() => {
        this.selectedAbsenceRequestData = {} as AbsenceRequest;
        this.selectedTablePage = 0;
        this.dataTableItems = [];
        this.userAbsences = {};
      }, 500);
    }).finally(() => clearTimeout(timeOut));
  }

  shouldShowCreateBtn(req: AbsenceRequest) {
    return (
      this.userMetaData.isAdmin &&
      (req.state === STATUS_NOT_PROCESSED || req.state === STATUS_REQUESTED)
    );
  }

  get shouldShowDataTableControls(): boolean {
    return (
      this.dateToKey(this.selectedAbsenceRequestData.startDate * 1000) !==
      this.dateToKey(this.selectedAbsenceRequestData.endDate * 1000)
    );
  }

  getFormattedRequestStatusOption(reqStatus: AbsenceRequestState) {
    return statusNamesByKey[reqStatus];
  }

  getExpandableHeaderData(absenceRequest: AbsenceRequest): ExpandableHeader {
    const extraSpace = !this.userHasReadRights ? " with-extra-space" : "";

    const headerData = [
      ...(this.userHasReadRights
        ? [
          {
            title: "Gestellt von",
            value: this.getFormattedUserTitle(
              (
                this.users.find(
                  user => user.userId === absenceRequest.employeeId
                ) || {}
              ).email as string
            ),
            className: "posted-by"
          }
        ]
        : []),
      {
        title: "Gestellt Am",
        value: formatDateToFormatStringInUTCTz(
          getDateContext(absenceRequest.creationDate),
          DATE_WITH_WEEKDAY_DATEFORMAT
        ),
        className: `posted-on${extraSpace}`
      },
      {
        title: "Von",
        value: formatDateToFormatStringInUTCTz(
          getDateContext(absenceRequest.startDate),
          DATE_WITH_WEEKDAY_DATEFORMAT
        ),
        className: `from-date${extraSpace}`
      },
      {
        title: "Bis",
        value: formatDateToFormatStringInUTCTz(
          getDateContext(absenceRequest.endDate),
          DATE_WITH_WEEKDAY_DATEFORMAT
        ),
        className: `to-date${extraSpace}`
      }
    ];

    if (!this.isOnMobileBrowser()) {
      headerData.push({
        title: "Status",
        value: this.getStatus(absenceRequest).concat(
          this.getApprovedRecipientsStatistics(absenceRequest)
        ),
        className: "request-status"
      });
    }

    return headerData;
  }

  getStatus(absenceRequest: AbsenceRequest) {
    return this.getFormattedRequestStatusOption(absenceRequest.state);
  }

  getApprovedRecipientsStatistics(absenceRequest: AbsenceRequest) {
    if (absenceRequest.state === "REQUESTED") {
      const approved = absenceRequest.recipients.filter(
        r => r.state === "APPROVED"
      ).length;
      const total = absenceRequest.recipients.length;
      return ` [${approved}/${total}]`;
    } else {
      return "";
    }
  }

  getExpandableBodyData({ recipients = [] }: AbsenceRequest): ExpandableBody[] {
    return recipients.map(({ email, state, lastReminder }) => {
      const entry = [
        {
          className: "recipient",
          value: this.getFormattedUserTitle(email)
        },
        {
          className: "last-reminder",
          value: this.getFormattedReminderData(
            lastReminder,
            statusNamesByKey[state]
          )
        },
        {
          className: "send-reminder-btn",
          value: this.getReminderTooltip(state, lastReminder)
        }
      ];

      if (!this.isOnMobileBrowser()) {
        entry.push({
          className: "recipient-status",
          value: statusNamesByKey[state]
        });
      }

      return entry;
    });
  }

  getFormattedReminderData(
    lastReminderTime: number | string = 0,
    recipientStatus: string
  ) {
    if (!lastReminderTime) {
      return "Es gibt keine Angaben!";
    }

    const readableDate = getDateContext(lastReminderTime).toLocaleDateString(
      "de"
    );

    return `${recipientStatus} ${readableDate}`;
  }

  getFormattedUserTitle(recipientEmail: string) {
    const existingUser = this.users.find(
      ({ email }) => email === recipientEmail
    );

    return existingUser
      ? `${existingUser.firstName} ${existingUser.lastName}`
      : recipientEmail;
  }

  get dataTableHeaders() {
    return this.getDaysOfMonth(
      getDateContext(this.selectedAbsenceRequestData.startDate)
    ).map(date => ({
      text: formatDateToFormatStringInUTCTz(date, "EE. d"),
      sortable: false,
      value: formatDateToFormatStringInUTCTz(date, "dd/MM/yyyy"),
      class: isWeekend(date) ? "weekend" : "",
      width: "3.2%",
      align: "left"
    }));
  }

  getDataTableItemsByMonth(
    shouldIncludeFullDateRange: boolean = false,
    cancellation: boolean = false
  ): DataTableItem[] {
    const startDate = this.selectedAbsenceRequestData.startDate;
    const endDate = this.selectedAbsenceRequestData.endDate;

    if (shouldIncludeFullDateRange) {
      const datesByRange = [] as Date[];

      let startDateContext = startOfMonth(getDateContext(startDate));
      const endDateContext = endOfMonth(getDateContext(endDate));

      while (isBefore(startDateContext, endDateContext)) {
        datesByRange.push(startDateContext);
        startDateContext = addMonths(startDateContext, 1);
      }

      return datesByRange.map(dateContext =>
        this.getDaysOfMonth(dateContext, false).map((date, index) => {
          return this.getTableItem(
            date,
            startDate,
            endDate,
            index,
            cancellation
          );
        })
      );
    }

    return [
      this.getDaysOfMonth(getDateContext(startDate)).map((date, index) => {
        return this.getTableItem(date, startDate, endDate, index, cancellation);
      })
    ];
  }

  getTableItem(
    date: Date,
    startDateRaw: string | number,
    endDateRaw: string | number,
    index: number,
    cancellation: boolean
  ): DataTableItemEntry {
    const startDate = getDateContext(startDateRaw);
    const endDate = getDateContext(endDateRaw);

    const isWeekEnd = isWeekend(date);

    const isOutOfRange =
      isBefore(startOfDay(date), startOfDay(startDate)) ||
      isAfter(startOfDay(date), startOfDay(endDate));

    const key = this.dateToKey(date);

    const monthData = this.userAbsences[key];
    let initialValue: VacationMapping | null = null;

    if (!monthData) {
      console.warn("no month data found for " + key);
    } else {
      const absences = monthData.absences || [];
      initialValue = absences[index] ? VACATION_TYPES.byAbbr(absences[index]) : null;
    }

    let prefilledValue : VacationMapping | null = null;
    const isPartOfRange =
      isBetween(date, subDays(startDate, 1), endDate) && !isWeekEnd;

    const dateFormatted = formatDateToFormatStringInUTCTz(date, "dd.MM");

    if (!cancellation) {
      if (!isPartOfRange) {
        // alle Werte ausserhalb des gewählten Zeitraums so lassen
        prefilledValue = initialValue;
      } else if (initialValue?.type === VacationAbbreviation.F || initialValue?.type === VacationAbbreviation.u) {
        // Feiertage belassen, halben Tag Urlaub auch halb belassen (wo sollte der herkommen ohne Konflikt?)
        prefilledValue = initialValue;
      } else if (initialValue?.type === VacationAbbreviation.f || initialValue?.type === VacationAbbreviation.fu) {
        // halbe Feiertage mit halbem Urlaub auffüllen
        prefilledValue = VACATION_TYPES.byAbbr(VacationAbbreviation.fu);
      } else if (dateFormatted === "24.12" || dateFormatted === "31.12") {
        // Heiligabend und Sylvester: default halber feiertag, halber Urlaubstag
        prefilledValue = VACATION_TYPES.byAbbr(VacationAbbreviation.fu);
      } else {
        // default: voller Urlaubstag soll genommen werden
        prefilledValue = VACATION_TYPES.byAbbr(VacationAbbreviation.U);
      }
    } else { // cancel request
      if (isPartOfRange && (dateFormatted === "24.12" || dateFormatted === "31.12")) {
        // Heiligabend und Sylvester: default halber feiertag
        prefilledValue = VACATION_TYPES.byAbbr(VacationAbbreviation.f);
      } else if (isPartOfRange && (initialValue?.type === VacationAbbreviation.U) || initialValue?.type === VacationAbbreviation.u) {
        // unselect days with Vacation
        prefilledValue = null;
      } else if (isPartOfRange && (initialValue?.type === VacationAbbreviation.fu)) {
        // back to half holiday
        prefilledValue = VACATION_TYPES.byAbbr(VacationAbbreviation.f);
      } else {
        // leave everything else as it is.
        prefilledValue = initialValue;
      }
    }

    return {
      disabled: isWeekEnd || isOutOfRange,
      date: formatDateToFormatStringInUTCTz(date, YEAR_MONTH_DAY_DATEFORMAT),
      month: this.dateToKey(date),
      class: isWeekEnd ? "weekend" : "",
      isNew: isPartOfRange && !initialValue && !!prefilledValue,
      isChanged: isPartOfRange && (prefilledValue != initialValue),
      selectedValue: prefilledValue,
      initialValue: initialValue
    };
  }

  getDaysOfMonth(
    dateContext: Date,
    shouldGetOnlySelectedMonthDays: boolean = true
  ): Date[] {
    const days = [];

    if (shouldGetOnlySelectedMonthDays) {
      this.selectedDateContext = this.selectedDateContext || dateContext;
    }

    const definedDateContext = !shouldGetOnlySelectedMonthDays
      ? dateContext
      : (this.selectedDateContext as Date);

    let firstDateOfMonth = startOfMonth(definedDateContext);
    const lastDateOfMonth = endOfMonth(definedDateContext);

    while (firstDateOfMonth <= lastDateOfMonth) {
      days.push(firstDateOfMonth);
      firstDateOfMonth = addDays(firstDateOfMonth, 1);
    }

    return days;
  }

  get amountOfRequestedVacations() {
    return this.dataTableItems.reduce(
      (acc, dataTableItem) =>
        acc +
        dataTableItem.reduce(
          (previousValue, { initialValue, selectedValue }) =>
            previousValue +
            (selectedValue ? selectedValue.vacationValue : 0) -
            (initialValue ? initialValue.vacationValue : 0),
          0
        ),
      0
    );
  }

  get yearlyEntitlementInfo(): Array<string | number> {
    const keysDistinctOnYear = Object.keys(this.userAbsences)
      .filter(
        (key0, index, arr) =>
          arr.findIndex(
            key1 => this.getYearFromKey(key1) === this.getYearFromKey(key0)
          ) === index
      )
      .sort((a, b) => this.getYearFromKey(a) - this.getYearFromKey(b)); // sort ascendingly

    const returnList = [];
    if (!keysDistinctOnYear.length) {
      return [];
    }

    const firstYearAbsence = this.userAbsences[keysDistinctOnYear[0]];
    if (firstYearAbsence.remainingPreviousYear != null) {
      returnList.push(
        `Resturlaub ${this.getYearFromKey(keysDistinctOnYear[0]) -
        1}: ${roundToTwoDecimals(firstYearAbsence.remainingPreviousYear)}`
      );
    }
    if (firstYearAbsence.remainingEntitlement != null) {
      returnList.push(
        `Resturlaub ${this.getYearFromKey(
          keysDistinctOnYear[0]
        )}: ${roundToTwoDecimals(firstYearAbsence.remainingEntitlement)}`
      );
    } else {
      returnList.push(this.getYearFromKey(keysDistinctOnYear[0]));
    }
    if (keysDistinctOnYear.length === 2) {
      const secondYearAbsence = this.userAbsences[keysDistinctOnYear[1]];
      if (secondYearAbsence.remainingEntitlement != null) {
        returnList.push(
          `Resturlaub ${this.getYearFromKey(
            keysDistinctOnYear[1]
          )}: ${roundToTwoDecimals(secondYearAbsence.remainingEntitlement)}`
        );
      } else {
        returnList.push(this.getYearFromKey(keysDistinctOnYear[1]));
      }
    } else if (keysDistinctOnYear.length > 2) {
      console.error("More than two years in change. This is not supported.");
      return [];
    }

    return returnList;
  }

  getYearFromKey(key: string): number {
    return Number(key.split(" ")[1]);
  }

  getStatesFromQuery() {
    const urlParameters = new URLSearchParams(window.location.search);

    const stateParameter = urlParameters.get("state");

    if (stateParameter == null) {
      return null;
    }

    return stateParameter.split(",");
  }

  getYearFromQuery() {
    const urlParameters = new URLSearchParams(window.location.search);

    return urlParameters.get("year");
  }

  get requestCreatorFullName() {
    const { employeeId } = this.selectedAbsenceRequestData;
    const { firstName, lastName } =
    this.users.find(user => user.userId === employeeId) || {};

    return `${firstName} ${lastName}`;
  }

  get formattedCurrentMonthYear() {
    return !!this.selectedDateContext && this.dateToKey(this.selectedDateContext)
  }

  formatSelectedDate(date: number | string): string {
    if (!date) {
      return "";
    }

    return formatDateToFormatStringInUTCTz(
      getDateContext(date),
      DATE_WITH_WEEKDAY_DATEFORMAT
    );
  }

  selectDataTablePage(dateContext: Date, handler: () => void) {
    Promise.resolve()
      .then(() => {
        const { employeeId } = this.selectedAbsenceRequestData;
        const key = this.dateToKey(dateContext);
        if (
          !this.userAbsences[key]
        ) {
          return this.fetchUserMonthAbsence(employeeId, dateContext);
        }

        return Promise.resolve(null);
      })
      .finally(handler);
  }

  goToNextMonth() {
    this.selectedDateContext = addMonths(this.selectedDateContext as Date, 1);

    this.selectDataTablePage(this.selectedDateContext, () => {
      if (
        !this.dataTableItems.some(
          dataTableItem =>
            dataTableItem[0].month === this.formattedCurrentMonthYear
        )
      ) {
        this.dataTableItems = [
          ...this.dataTableItems,
          ...this.getDataTableItemsByMonth()
        ];

        return (this.selectedTablePage = this.dataTableItems.length - 1);
      }

      (this.selectedTablePage as number)++;
    });
  }

  goToPreviousMonth() {
    this.selectedDateContext = subMonths(this.selectedDateContext as Date, 1);

    this.selectDataTablePage(this.selectedDateContext, () => {
      if (
        !this.dataTableItems.some(
          dataTableItem =>
            dataTableItem[0].month === this.formattedCurrentMonthYear
        )
      ) {
        this.dataTableItems = [
          ...this.getDataTableItemsByMonth(),
          ...this.dataTableItems
        ];

        return (this.selectedTablePage = 0);
      }

      (this.selectedTablePage as number)--;
    });
  }

  onChangeAbsence(absenceEntry: DataTableItemEntry) {
    if (absenceEntry.selectedValue === absenceEntry.initialValue ||
      (!absenceEntry.selectedValue && !absenceEntry.initialValue)) {
      absenceEntry.isChanged = false;
      absenceEntry.isNew = false
    } else if (!absenceEntry.initialValue) {
      absenceEntry.isNew = true;
      absenceEntry.isChanged = false;
    } else {
      absenceEntry.isNew = false;
      absenceEntry.isChanged = true;
    }
  }

  isNavButtonDisabled(date: number) {
    return (
      this.dateToKey(date * 1000) ===
      this.dateToKey(this.selectedDateContext)
    );
  }

  canBeCancelled({ employeeId, state }: AbsenceRequest = {} as AbsenceRequest) {
    const {
      userMetaData: { userId }
    } = this;

    return (
      (employeeId === userId || this.userMetaData.isAdmin) &&
      state !== STATUS_PROCESSED &&
      state !== STATUS_CANCELLED &&
      state !== STATUS_CANCELLATION_REQUESTED
    );
  }

  getArrayOfPreopenedRequests(): boolean[] {
    const openedRequests: boolean[] = [];
    const hash = this.getHash();

    for (const request of this.absenceRequests) {
      openedRequests.push(hash.length !== 0 && hash === request.id);
    }

    if (hash.length !== 0 && openedRequests.every(value => !value)) {
      this.transactionError = true;
      this.transactionStatus =
        "Dieser Urlaubsantrag konnte nicht gefunden werden";
      this.snackbar = true;
    }

    return openedRequests;
  }

  getHash() {
    return window.location.hash.substring(1);
  }

  async fetchAbsenceRequests(): Promise<AbsenceRequest[]> {
    this.requestHandled = false;
    const requestFilter = {
      userIds: this.getSelectedUsers.map(user => user.userId),
      states: this.selectedReqStatuses,
      year: this.selectedYear
    };

    return this.axios
      .post("/api/getAbsenceRequests", requestFilter)
      .then(response => (this.absenceRequests = response.data))
      .catch(error => (this.errorLoadingData = error));
  }

  updateDataAfterChange() {
    this.fetchAbsenceRequests().finally(() => (this.requestHandled = true));
  }

  async sendAbsenceRequestReminder(recipient: Recipient) {
    return this.axios
      .post("/api/remindRecipient", recipient)
      .then(() => {
        this.showUserNotification("Erinnerungsmail versendet an " + recipient.email, false);
        this.updateDataAfterChange()
      })
      .catch(error => {
        this.showUserNotification(getUserMessageForErrorReason(error), true);
      });
  }

  getReminderTooltip(state: RecipientState, lastReminder: number): string {
    if (state !== STATUS_REQUESTED) {
      return "EmpfängerIn hat den Antrag bereits beantwortet";
    }
    if (differenceInHours(new Date(), getDateContext(lastReminder)) < 24) {
      return "Die letzte Mail wurde vor weniger als 24 Stunden geschickt";
    }

    return "";
  }

  // Due to Husky's Git Checks this is the only way to not trigger "no identical methods"
  cancelAbsenceRequest(
    absenceRequest: AbsenceRequest,
    requestCancellation: boolean = false
  ) {
    const confirmationMessage = requestCancellation
      ? "Soll eine Stornierung beantragt werden?"
      : "Soll dieser Antrag wirklich storniert werden?";
    const postEndpoint = requestCancellation
      ? "/api/requestCancelAbsenceRequest"
      : "/api/cancelAbsenceRequest";

    if (!confirm(confirmationMessage)) {
      return;
    }

    return this.axios
      .post(postEndpoint, absenceRequest)
      .then(() => this.updateDataAfterChange())
      .catch(error => {
        this.showUserNotification(getUserMessageForErrorReason(error), true);
      });
  }

  showUserNotification(message: string, isError: boolean) {
    this.transactionStatus = message;
    this.transactionError = isError;
    this.snackbar = true;
  }

  openEntitlementForm(year: number) {
    // Set it here, so it is set even if getUserEntitlement fails
    this.selectedEntitlementData = {
      userId: this.selectedAbsenceRequestData.employeeId,
      year
    } as UserEntitlementData;

    this.shouldShowEntitlementForm = true;
  }

  closeEntitlementForm() {
    this.shouldShowEntitlementForm = false;
    this.selectedEntitlementData = {} as UserEntitlementData;
  }

  async saveEntitlement(data: EntitlementData) {
    return this.axios
      .post("/api/changeUserEntitlement", {
        affectedEmployee: this.selectedEntitlementData.userId,
        ...data
      })
      .then(() => {
        this.transactionError = false;
        this.snackbar = true;
        this.transactionStatus =
          "Die Änderungen wurden erfolgreich eingetragen";
        const keysOfYear = Object.keys(this.userAbsences).filter(
          key => this.getYearFromKey(key) === this.selectedEntitlementData.year
        );
        this.userAbsences = Object.assign(
          {},
          this.userAbsences,
          Object.fromEntries(
            keysOfYear.map(key => [
              key,
              {
                remainingEntitlement: data.remainingEntitlement
              } as AbsencesInMonthlyOverview
            ])
          )
        );
        return this.fetchUserMonthAbsence(
          this.selectedEntitlementData.userId,
          this.selectedDateContext as Date
        );
      })
      .catch(reason => {
        this.transactionError = true;
        this.snackbar = true;
        this.transactionStatus = getUserMessageForErrorReason(reason);
      })
      .finally(this.closeEntitlementForm);
  }

  get userHasReadRights(): boolean {
    return hasReadRights(this.userMetaData);
  }

  get userIsAdmin(): boolean {
    return this.userMetaData.isAdmin;
  }
}
</script>

<style lang="scss">
.absence-requests {
  overflow-x: auto;

  .v-expansion-panel {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  }
}

.absence-filter-section {
  margin-bottom: 2.5%;
}

.absence-requests-list {
  padding: 2.5%;
}

.user-filter {
  margin-bottom: 1%;
}

.status-and-year-filter {
  display: flex;

  .status-filter {
    flex: 0 0 75%;
    margin-right: 5%;
  }

  .year-filter {
    margin-top: 12px;
  }
}

.no-absence-date,
.loading-data-error {
  padding: 7.5em;
  font-size: calc(1rem + 2px);
}

.v-expansion-panel__container {
  /* stylelint-disable */
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  /* stylelint-enable */
  margin-bottom: 0.25%;

  .bold {
    font-weight: bold;
  }

  @media (max-width: 750px) {
    .request-status {
      flex: 0 0 100%;
      padding: 16px 12px;
    }
  }

  .expandable-header {
    &.posted-by,
    &.posted-on,
    &.from-date,
    &.to-date {
      flex: 0 0 22%;

      &.with-extra-space {
        flex: 0 0 29%;
      }
    }

    &.request-status {
      flex: 0 0 10%;
    }

    .header-title {
      font-weight: bold;
      padding-bottom: 5.5%;
    }
  }

  .expandable-header,
  .expandable-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .expandable-body {
    padding: 0 24px;
    align-items: center;
    border-top: 1px solid lightgrey;
  }

  .expandable-body-column {
    flex: 0 0 25%;

    &.absence-request-note {
      flex: 0 0 100%;
      padding: 16px 12px;

      span {
        display: block;
        font-weight: bold;
        margin-right: 1.5%;
      }
    }

    @media (max-width: 750px) {
      &.absence-request-note {
        flex: 0 0 100%;
        padding: 16px 12px;

        span {
          display: block;
          font-weight: bold;
          margin-right: 1.5%;
        }
      }
    }
  }

  .cancel-processed-button {
    display: flex;
    justify-content: flex-end;
    padding: 16px 12px;
  }

  .recipient-status {
    flex: 0 0 20%;
  }

  .buttons {
    justify-content: flex-end;

    .cancel-request-btn,
    .create-request-btn {
      padding: 12px 0;
    }

    .cancel-request-btn {
      order: 2;
      flex: 0 0 20%;
    }

    .create-request-btn {
      order: 1;
      margin-right: 2.5%;
    }
  }

  .expandable-header-column,
  .expandable-body-column {
    padding: 12px;
  }
}

.v-dialog {
  .table-caption,
  .table-footer {
    margin: 0 25px;
    border: 1px solid lightgrey;
  }

  .absence-request-create-table {
    margin: 0 25px;

    .weekend {
      background-color: #d4d8f9 !important;
      min-width: 1.5em;
      max-width: 2.5em;
    }

    thead {
      border: 1px solid lightgrey;

      th[role="columnheader"] {
        border-right: 1px dashed grey;

        .table-header {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }

    tbody {
      border: 1px solid lightgrey;

      td {
        border-right: 1px dashed grey;

        .added {
          background-color: var(--v-success-darken1);
        }

        .changed {
          background-color: var(--v-warning-base);
        }
      }
    }
  }

  .comment-area {
    margin: 2.5% 25px;
  }

  .table-controls {
    margin-top: 20px;
  }

  .select-vacation-date-section {
    margin: 0 25px;
    padding: 15px;
    border: 1px solid lightgrey;
  }

  .action-buttons {
    .save-btn {
      margin-right: 2%;
    }
  }
}
</style>
