import "./routerHooksPatch";
import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "./plugins/axios";
import App from "./app.vue";
import store from "./store/store";
import router from "./router";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import Vuetify from "vuetify/lib";
import VueRx from "vue-rx";
import "vuetify/src/stylus/app.styl";
import { isOnMobileBrowser } from "./utils";
import { VACATION_TYPES} from "@/constants";

Vue.prototype.$vacation = VACATION_TYPES;

Vue.use(Vuetify, {
  theme: {
    tertiary: "#05326e",
    accent: "#ffa12d",
    primary: "#5f5f5f",
    secondary: "#0072cf"
  },
  options: {
    iconfont: "md",
    customProperties: true
  }
});
Vue.use(VueRx);
Vue.use(VueAxios, axios);

Vue.mixin({
  methods: {
    isOnMobileBrowser
  }
});

declare module "vue/types/vue" {
  interface Vue {
    isOnMobileBrowser: () => boolean;
    $vacation: typeof VACATION_TYPES;
  }
}

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  router,
  store
}).$mount("#app");
