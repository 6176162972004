<template>
  <div>
    <div v-if="userHasReadRights" class="filter-container" ref="userFilter">
      <user-filter
        class="filter-input"
        :updateUserSelection="loadData"
        :contentIsLoaded="requestHandled"
        :user-request-accuracy="{ year: parseInt($route.params.year) }"
        overview-type="yearly"
      ></user-filter>
    </div>

    <div class="yearly-overview-wrapper" ref="tableWrapper">
      <v-snackbar
        v-model="snackbar"
        :color="transactionError ? 'error' : 'success'"
        top
      >
        {{ transactionStatus }}
        <v-btn color="white" flat @click="onCloseSnackbar()"> Schließen</v-btn>
      </v-snackbar>

      <div
        v-if="requestHandled && absencesInYear"
        class="yearly-overview-layout"
        :class="{ 'overflowed-layout-view': userHasReadRights }"
      >
        <v-data-table
          v-if="requestHandled"
          :headers="tableHeaders"
          class="absence-table text-sm-center table-header"
          :items="[]"
          hide-actions
          ref="tableHeader"
        >
          <template v-slot:no-data>
            <tr style="visibility: hidden"></tr>
          </template>
          <template v-slot:headerCell="props">
            <router-link
              v-if="props.header.href"
              :to="props.header.href"
              class="link-header"
              >{{ props.header.text }}
            </router-link>
            <v-tooltip
              bottom
              v-else
              :disabled="
                !(props.header.shortText && shouldRenderTableHeaderTooltip)
              "
            >
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <span
                    v-if="
                      props.header.shortText && shouldRenderTableHeaderTooltip
                    "
                  >
                    {{ props.header.shortText }}...
                  </span>
                  <span v-else>
                    {{ props.header.text }}
                  </span>
                </div>
              </template>
              <span class="table-header-tooltip-text">{{
                props.header.text
              }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <div class="table-body" ref="tableBody" v-if="requestHandled">
          <div v-for="role in userRolesSort" :key="role" :id="role">
            <div v-if="absencesInYear[role]">
              <div v-if="userHasReadRights" class="user-role-row">
                {{ userRoles[role] }}
              </div>
              <div>
                <v-data-table
                  v-if="absencesInYear"
                  hide-headers
                  :items="absencesInYear[role]"
                  class="absence-table text-sm-center"
                  hide-actions
                  hide-default-footer
                >
                  <template v-slot:items="props">
                    <td class="user-name-year">
                      {{ props.item.user.lastName }},
                      {{ props.item.user.firstName }}
                    </td>
                    <td
                      v-for="monthName in monthKeysArray"
                      class="absences-in-month"
                      :key="monthName"
                    >
                      {{ props.item.absencesInMonths[monthName] }}
                    </td>
                    <td class="other-absences-data">{{ props.item.sum }}</td>
                    <td class="other-absences-data">
                      {{ roundToTwoDecimals(props.item.plannedVacation) }}
                    </td>
                    <td class="other-absences-data">
                      {{ roundToTwoDecimals(props.item.usableEntitlement) }}
                    </td>
                    <td class="other-absences-data">
                      {{ roundToTwoDecimals(props.item.remainingEntitlement) }}
                    </td>
                    <td class="other-absences-data">
                      {{
                        roundToTwoDecimals(
                          props.item.remainingPreviousYearEndOfYear
                        )
                      }}
                    </td>
                    <td class="other-absences-data">
                      {{ roundToTwoDecimals(props.item.remainingPreviousYear) }}
                    </td>
                    <td class="other-absences-data">
                      {{ roundToTwoDecimals(props.item.yearlyEntitlement) }}
                    </td>
                    <td class="other-absences-data">
                      <v-tooltip
                        dark
                        left
                        :disabled="
                          !monthsWithSicknessForUser(props.item.user, 'K')
                        "
                      >
                        <template v-slot:activator="{ on }">
                          <span v-on="on">{{ props.item.sickness }}</span>
                        </template>
                        <v-list dark>
                          <v-list-tile
                            v-for="monthKey in monthsWithSicknessForUser(
                              props.item.user,
                              'K'
                            )"
                            :key="monthKey"
                          >
                            {{ monthNamesObject[monthKey] }}:
                            {{ props.item.sicknessInMonths[monthKey] }}
                          </v-list-tile>
                        </v-list>
                      </v-tooltip>
                    </td>
                    <td class="other-absences-data">
                      <v-tooltip
                        left
                        :disabled="
                          !monthsWithSicknessForUser(props.item.user, 'KK')
                            .length
                        "
                      >
                        <template v-slot:activator="{ on }">
                          <span v-on="on">{{ props.item.childSick }}</span>
                        </template>
                        <v-list dark>
                          <v-list-tile
                            v-for="monthKey in monthsWithSicknessForUser(
                              props.item.user,
                              'KK'
                            )"
                            :key="monthKey"
                          >
                            {{ monthNamesObject[monthKey] }}:
                            {{ props.item.childSickInMonths[monthKey] }}
                          </v-list-tile>
                        </v-list>
                      </v-tooltip>
                    </td>
                    <td
                      class="remark-year"
                      :class="{
                        ellipsized: isOverflowingTableCell(
                          `remark-year_${role}_${props.item.user.userId}`
                        )
                      }"
                      :ref="`remark-year_${role}_${props.item.user.userId}`"
                    >
                      <v-tooltip
                        bottom
                        :disabled="
                          !remarkCellsOverflowStates[
                            `remark-year_${role}_${props.item.user.userId}`
                          ]
                        "
                        max-width="300"
                      >
                        <template v-slot:activator="{ on }">
                          <span v-on="on" class="remark-text">{{
                            props.item.remark || "—"
                          }}</span>
                        </template>
                        <span class="remark-tooltip-text">{{
                          props.item.remark || "—"
                        }}</span>
                      </v-tooltip>
                    </td>
                  </template>
                </v-data-table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div id="loadingSpinner" v-if="!requestHandled"></div>
        <div class="no-absence-date" v-else-if="!errorLoadingData">
          <strong> Es gibt keine Einträge in diesem Jahr </strong>
        </div>
        <div v-else class="loading-data-error">
          <strong>
            Beim Versuch die Daten zu laden ist ein Fehler aufgetreten:
            {{ errorLoadingData }}
          </strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import {Route} from "vue-router";

import {
  ABSENCE_DATA_ACTIONS,
  ABSENCE_DATA_GETTERS,
  ABSENCE_DATA_MUTATIONS,
  AbsenceSpace
} from "@/store/modules/absenceData";
import {findKeyByValue, getUserMessageForErrorReason, hasReadRights, roundToTwoDecimals} from "@/utils";
import {Absences, AbsencesInYearlyOverview, User, UserMetaData, UserRequestAccuracy} from "@/models";
import {ALL_MONTHS, monthNamesByKey, userRolesByKey} from "@/constants";
import UserFilter from "../common/userFilter.vue";

// TODO Typesafe extension of DataTableComponentHeaders like in
// https://stackoverflow.com/questions/78139679/how-to-import-typescript-types-for-vuetify-properties-like-variant
// What you can change about a table header
interface TableHeader {
  shortText?: string,
  text: string,
  href?: string,
  sortable: boolean,
  class: string
}

@Component({ components: { userFilter: UserFilter } })
export default class YearlyOverview extends Vue {
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getAllAbsencesInYear)
  absencesInYear!: Absences<AbsencesInYearlyOverview>;
  @AbsenceSpace.Action(ABSENCE_DATA_ACTIONS.fetchAbsencesOfYear)
  fetchAbsencesOfYear!: ([year, users]: [number, string[]]) => Promise<
    Absences<AbsencesInYearlyOverview>
  >;
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getUserMetaData)
  userMetaData!: UserMetaData;
  @AbsenceSpace.Action(ABSENCE_DATA_ACTIONS.fetchUserMetaData)
  fetchUserMetaData!: () => Promise<UserMetaData>;
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getSelectedUsers)
  getSelectedUsers!: User[];

  @AbsenceSpace.Action(ABSENCE_DATA_ACTIONS.fetchAllValidUsers)
  fetchAllValidUsers!: (
    userRequestAccuracy: UserRequestAccuracy
  ) => Promise<User[]>;
  @AbsenceSpace.Mutation(ABSENCE_DATA_MUTATIONS.setValidUsers)
  setAllValidUsers!: (users: User[]) => void;
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getAllValidUsers)
  allValidUsers!: User[];

  @AbsenceSpace.Mutation(ABSENCE_DATA_MUTATIONS.setSelectedUsers)
  setSelectedUsers!: (selectedUsers: User[]) => void;

  userRoles = { ...userRolesByKey };
  userRolesSort = Object.getOwnPropertyNames(this.userRoles);
  selectedUsers: User[] = [];

  monthNamesObject = { ...monthNamesByKey };
  monthKeysArray: string[] = Object.getOwnPropertyNames(monthNamesByKey);

  requestHandled = false;
  errorLoadingData = null;
  tableHeaders: TableHeader[] = [];

  currentWindowWidth: number = 0;

  remarkCellsOverflowStates = {} as { [key: string]: boolean };

  roundToTwoDecimals = roundToTwoDecimals;

  transactionStatus = "";
  snackbar = false;
  transactionError = false;

  created() {
    window.addEventListener("resize", this.onResize);
  }

  mounted() {
    new Promise(resolve => {
      if (!!this.userMetaData.userId) {
        this.fetchUserMetaData()
          .catch(error => {
            this.transactionStatus = getUserMessageForErrorReason(error);
            this.transactionError = true;
            this.snackbar = true;
          })
          .finally(() => {
            resolve(null);
          });
      } else {
        resolve(null);
      }
    }).then(() => {
      this.loadData();
      this.setTableHeaders();
    });
  }

  updated() {
    this.onResize();
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  }

  onResize() {
    this.currentWindowWidth = window.innerWidth;

    Object.keys(this.$refs).forEach((refKey: string) => {
      this.remarkCellsOverflowStates = {
        ...this.remarkCellsOverflowStates,
        [refKey]: this.isOverflowingTableCell(refKey)
      };
    });

    // Small delay to ensure correct content height recalculation when resizing screen
    const timeout = setTimeout(() => {
      this.adjustTableLayout();
      clearTimeout(timeout);
    }, 300);
  }

  get shouldRenderTableHeaderTooltip() {
    return this.currentWindowWidth <= 1500;
  }

  loadData() {
    let usersToLoad: string[];
    // load data UNLESS we're running tests
    if (process.env.NODE_ENV !== "test") {
      this.requestHandled = false;
      if (
        this.getSelectedUsers.length > 0 &&
        (this.userMetaData.isAdmin ||
          this.userMetaData.isManager ||
          this.userMetaData.isReviewer)
      ) {
        usersToLoad = this.getSelectedUsers.map(user => user.userId);
      } else {
        usersToLoad = [this.userMetaData.userId];
      }
      this.fetchAbsencesOfYear([
        parseInt(this.$route.params.year, 10),
        usersToLoad
      ])
        .catch(error => (this.errorLoadingData = error))
        .finally(() => {
          this.requestHandled = true;
        });
    } else {
      // test should get everything loaded
      this.requestHandled = true;
    }
  }

  // is called when route parameter (e.g. year) changes, explicitly reloads data when that happens
  beforeRouteUpdate(to: Route, from: Route, next: any) {
    // propagate the route update event
    next();
    // only on changes to year/month we want to reload data, as we do it already on changes to the users
    if (to.path !== from.path) {
      this.loadData();
    }

    this.setTableHeaders();
  }

  monthsWithSicknessForUser(user: User, KorKK: "K" | "KK"): string[] {
    if (!this.absencesInYear) {
      return [];
    }
    const absences: AbsencesInYearlyOverview[] = this.absencesInYear[
      findKeyByValue(this.userRoles, user.userRole)
      ];
    const absenceFromUser = absences.find(
      absence => absence.user.userId === user.userId
    );
    if (absenceFromUser) {
      if (KorKK === "K") {
        return this.monthKeysArray.filter(
          month => absenceFromUser.sicknessInMonths[month] > 0
        );
      } else {
        return this.monthKeysArray.filter(
          month => absenceFromUser.childSickInMonths[month] > 0
        );
      }
    } else {
      return [];
    }
  }

  onCloseSnackbar() {
    this.snackbar = false;
    this.transactionStatus = "";
  }

  adjustTableLayout() {
    if (this.userHasReadRights) {
      const tableHeader = (
        (this.$refs.tableHeader as Record<string, any>) || {}
      ).$el as HTMLElement;

      const tableBody = this.$refs.tableBody as HTMLElement;

      if (tableHeader && tableBody) {
        const layoutHeader = (this.$attrs as Record<string, any>)
          .layoutHeader as HTMLElement;
        const userFilter = this.$refs.userFilter as HTMLElement;
        const tableWrapper = this.$refs.tableWrapper as HTMLElement;

        // Check if the table wrapper has a horizontal scrollbar
        const isOverflowingWidth =
          tableWrapper.clientWidth < tableWrapper.scrollWidth;

        // Check if the table body has a vertical scrollbar
        const isOverflowingHeight =
          tableBody.clientHeight < tableBody.scrollHeight;

        // In case of horizontal scrollbar on the table wrapper:
        // Calculating scrollbar space in order to exclude it by table body height calculation
        const horizontalScrollSpace = isOverflowingWidth
          ? tableWrapper.offsetHeight - tableWrapper.clientHeight
          : 0;

        // Calculating table body height dynamically (full view port height excluding everything except of table body)
        tableBody.style.height = `calc(100vh - ${tableHeader.offsetHeight +
        userFilter.offsetHeight +
        layoutHeader.offsetHeight}px - ${horizontalScrollSpace}px)`;

        // In case of vertical scrollbar on the table body:
        // Vertical scrollbar takes some extra space, it breaks the table layout (columns of the table header and footer are misaligned in relation to each other)
        // In this case scroll bar space on the table body will calculate and applied as margin-left to the table header
        if (isOverflowingHeight) {
          const verticalScrollSpace =
            tableBody.offsetWidth - tableBody.clientWidth;

          tableHeader.style.marginRight = `${verticalScrollSpace}px`;
        } else {
          tableHeader.style.marginRight = `0px`;
        }
      }
    }
  }

  isOverflowingTableCell(refKey: string) {
    const tableCellRef = this.$refs[refKey] as HTMLElement[];

    if (tableCellRef) {
      const tableCellElement = tableCellRef[0] || {};

      return tableCellElement.scrollWidth > tableCellElement.clientWidth;
    }

    return false;
  }

  private setTableHeaders() {
    const monthURL = "/overview/month/" + this.$route.params.year;
    const absencesClass = "absences-in-month";
    const otherAbsences = "other-absences-data";

    const monthHeaders: TableHeader[] = ALL_MONTHS.map( (m,index) =>
    {
      return {
        href: monthURL + "/" + (index + 1) + '?' + this.$route.fullPath.split('?')[1],
        text: monthNamesByKey[m],
        shortText: m,
        value: m,
        class: absencesClass,
        sortable: false
      };
    });

    this.tableHeaders = [
      {
        text: "Name",
        shortText: "Name",
        class: "user-name-year",
        sortable: false
      },
      ...monthHeaders,
      {
        text: "Summe genehmigter Urlaube",
        shortText: "Summe U",
        class: otherAbsences,
        sortable: false
      },
      {
        text: "offen (Mo-Fr)",
        shortText: "gepl. Urlaub",
        class: otherAbsences,
        sortable: false
      },
      {
        text: "Verfügbare Urlaubstage",
        shortText: "verfügbar",
        class: otherAbsences,
        sortable: false
      },
      {
        text: "Verfügbare Urlaubstage dieses Jahr",
        shortText: "verfgb. Jahr",
        class: otherAbsences,
        sortable: false
      },
      {
        text: "Resturlaub zu Jahresbeginn",
        shortText: "Rest Beginn Vorjahr",
        class: otherAbsences,
        sortable: false
      },
      {
        text: "Resturlaub Vorjahr übrig",
        shortText: "Rest Vorjahr",
        class: otherAbsences,
        sortable: false
      },
      {
        text: "Anzahl Urlaub Gesamt p.a.",
        shortText: "Urlaub Gesamt",
        class: otherAbsences,
        sortable: false
      },
      {
        text: "Summe Krankheit",
        shortText: "Summe K",
        class: otherAbsences,
        sortable: false
      },
      {
        text: "Summe Kindkrank",
        shortText: "Summe KK",
        class: otherAbsences,
        sortable: false
      },
      {
        text: "Anmerkung",
        shortText: "Anmerkung",
        class: "remark-year",
        sortable: false
      }
    ];
  }

  get userHasReadRights() {
    return hasReadRights(this.userMetaData);
  }
}
export { UserFilter };
</script>

<style lang="scss">
table.v-table thead th.column:first-child,
table.v-table thead th.column:not(:first-child),
table.v-table tbody tr td:first-child,
table.v-table tbody tr td:not(:first-child) {
  padding: 0 0;
  font-size: calc(1rem + 2px);
  word-wrap: break-word;
  white-space: normal;
}

table.v-table thead th > span {
  padding: 0.75rem 0;
  overflow-wrap: inherit;
}

.yearly-overview-wrapper {
  overflow-x: auto;

  .yearly-overview-layout {
    min-width: 1200px;
    position: relative;
    overflow-y: hidden;

    &.overflowed-layout-view {
      .table-body {
        overflow-y: auto;
      }
    }

    .absence-table {
      .absences-in-month {
        min-width: 0.55em;
        max-width: 0.55em;
      }

      .other-absences-data {
        min-width: 1.5em;
        max-width: 1.5em;
      }

      .user-name-year {
        min-width: 3em;
        max-width: 3em;
        justify-content: center;
        word-wrap: break-word;
        white-space: normal;
      }

      .link-header {
        color: rgba(0, 0, 0, 0.54);

        &:hover {
          color: rgba(0, 0, 0, 0.87);
        }
      }

      .absences-in-month,
      .other-absences-data,
      .remaining-entitlement-td,
      .remark-year {
        border-right: unset;
        border-left: 1px dashed;
      }

      th,
      td {
        &:nth-child(14) {
          border-left-width: 2px;
          border-left-style: solid;
        }

        &.remark-year {
          white-space: nowrap;
          min-width: 4em;
          max-width: 4em;
          padding: 0 10px;

          &.ellipsized {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  .no-absence-date,
  .loading-data-error {
    padding: 7.5em;
    font-size: calc(1rem + 2px);
  }
}

.table-header-tooltip-text {
  font-size: 1.2rem;
}
</style>
